<template>
  <ui-component-modal
    :modalTitle="'Edit group size settings'"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :onClickCancel="onClickCancel"
    :onClickSave="saveYieldSettings"
    :hideSaveButton="isSavingError || isSavingSuccess"
    :buttonLabelCancel="isSavingError || isSavingSuccess ? 'Close' : 'Cancel'"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <table class="table is-fullwidth is-narrow is-striped">
        <tbody>
          <tr v-if="sortedGroupSizes.length === 0">
            <td colspan="5">
              <article class="message is-info is-small has-margin-bottom">
                <div class="message-body">No group sizes defined</div>
              </article>
            </td>
          </tr>
          <template v-if="sortedGroupSizes.length > 0">
            <tr v-for="(groupSize, index) in sortedGroupSizes" :key="index">
              <td width="50">
                <span>{{ getPreviousGroupSize(index) }} - </span>
              </td>
              <td width="75">
                <input
                  type="text"
                  maxSize="4"
                  v-model="groupSize.MaxSize"
                  class="input is-small"
                />
              </td>
              <td width="100">
                <div class="field has-addons">
                  <p class="control">
                    <input
                      type="text"
                      v-model.number="groupSize.Percentage"
                      class="input is-small"
                    />
                  </p>
                  <p class="control">
                    <a class="button is-small is-static">%</a>
                  </p>
                </div>
              </td>
              <td>
                <span v-if="groupSize.Percentage < 0" class="is-size-7"
                  >Discount</span
                >
                <span v-if="groupSize.Percentage > 0" class="is-size-7"
                  >Surcharge</span
                >
              </td>
              <td>
                <a @click="removeGroupSize(index)">
                  <span class="icon has-text-danger">
                    <font-awesome-icon :icon="['fas', 'times']" />
                  </span>
                </a>
              </td>
            </tr>
          </template>
        </tbody>
        <tfoot>
          <tr v-if="newGroupSize">
            <td width="50">
              <span
                >{{ getPreviousGroupSize(mYieldSettings.GroupSizes.length) }}
                -
              </span>
            </td>
            <td width="75">
              <input
                type="text"
                maxSize="4"
                v-model.number="newGroupSize.MaxSize"
                class="input is-small"
                :class="{ 'is-danger': !isMaxGreaterThenMin || isMaxSizeEmpty }"
              />
            </td>
            <td width="100">
              <div class="field has-addons">
                <p class="control">
                  <input
                    type="text"
                    v-on:keyup.13="addGroupSize"
                    v-model.number="newGroupSize.Percentage"
                    class="input is-small"
                    :class="{ 'is-danger': isPercentageEmpty }"
                  />
                </p>
                <p class="control">
                  <a class="button is-small is-static">%</a>
                </p>
              </div>
            </td>
            <td>
              <span class="is-size-7">{{ actionLabel }}</span>
            </td>
            <td width="40">
              <a @click="addGroupSize">
                <span class="icon has-text-success">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </span>
              </a>
            </td>
          </tr>
        </tfoot>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import yieldProvider from '@/providers/yield'

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      mYieldSettings: null,
      newGroupSize: null,
    }
  },

  computed: {
    ...mapState('yieldStore', ['yieldSettingsState']),

    sortedGroupSizes() {
      let self = this
      let groupsizes = []
      if (self.mYieldSettings && self.mYieldSettings.GroupSizes.length > 0) {
        // groupsizes = self.mYieldSettings.GroupSizes.sort(function(a, b) {
        //   return a.MaxSize - b.MaxSize
        // })
        groupsizes = self.mYieldSettings.GroupSizes
      }
      return groupsizes
    },

    actionLabel() {
      let label = ''

      if (this.newGroupSize && this.newGroupSize.Percentage) {
        if (this.newGroupSize.Percentage < 0) {
          label = 'discount'
        }
        if (this.newGroupSize.Percentage > 0) {
          label = 'surcharge'
        }
      }

      return label
    },

    isMaxGreaterThenMin() {
      let isGreater = false

      if (this.mYieldSettings && this.newGroupSize) {
        let existingGroupSizes = this.mYieldSettings.GroupSizes.length
        let previousMaxSize = this.getPreviousGroupSize(existingGroupSizes)
        if (this.newGroupSize.MaxSize >= previousMaxSize) {
          isGreater = true
        }
      }

      return isGreater
    },

    isMaxSizeEmpty() {
      let isEmpty = false

      if (this.mYieldSettings && this.newGroupSize) {
        if (
          this.newGroupSize.MaxSize === null ||
          this.newGroupSize.MaxSize === ''
        ) {
          isEmpty = true
        }
      }

      return isEmpty
    },

    isPercentageEmpty() {
      let isEmpty = false

      if (this.mYieldSettings && this.newGroupSize) {
        if (
          this.newGroupSize.Percentage === null ||
          this.newGroupSize.Percentage === ''
        ) {
          isEmpty = true
        }
      }

      return isEmpty
    },
  },

  created() {
    this.mYieldSettings = JSON.parse(
      JSON.stringify(this.yieldSettingsState.yieldSettings)
    )

    this.setNewGroupSizeDefaults()
  },

  methods: {
    ...mapMutations('yieldStore', ['setYieldSettingsState']),

    getPreviousGroupSize(index) {
      let self = this
      let previousGroupSize = null
      let size = 1

      if (index > 0) {
        previousGroupSize = self.mYieldSettings.GroupSizes[index - 1]
        if (previousGroupSize) {
          size = Number(previousGroupSize.MaxSize) + 1
        }
      }
      return size
    },

    setNewGroupSizeDefaults() {
      let self = this
      let existingGroupSizes = self.mYieldSettings.GroupSizes.length
      let maxSize = 1
      if (existingGroupSizes > 0) {
        let previousMaxSize = self.getPreviousGroupSize(existingGroupSizes)
        maxSize = Number(previousMaxSize) + 5 - 1
      }

      this.newGroupSize = {
        ChannelId: self.mYieldSettings.ChannelId,
        LocationId: self.mYieldSettings.LocationId,
        MaxSize: maxSize,
        Percentage: 0,
      }
    },

    addGroupSize() {
      let self = this
      if (
        this.isMaxGreaterThenMin &&
        !this.isMaxSizeEmpty &&
        !this.isPercentageEmpty
      ) {
        self.mYieldSettings.GroupSizes.push(this.newGroupSize)
        self.setNewGroupSizeDefaults()
      }
    },

    removeGroupSize(index) {
      let self = this
      self.mYieldSettings.GroupSizes.splice(index, 1)
    },

    saveYieldSettings() {
      let self = this
      self.isSaving = true

      self.yieldSettingsState.yieldSettings = self.mYieldSettings

      yieldProvider.methods
        .updateLocationYieldSettings(self.mYieldSettings)
        .then((response) => {
          if (response.status === 200) {
            self.setYieldSettingsState(self.yieldSettingsState)

            self.onClickCancel()
          }
        })
        .catch((error) => {
          self.isSavingError = true
        })
        .finally(() => {
          self.isSaving = false
        })
    },
  },
}
</script>
